import React, { FC } from "react"

const NotFoundPage: FC = () => (
  <>
    <h1>Niet gevonden</h1>
    <p>De opgevraagde pagina is niet gevonden.</p>
  </>
)

export default NotFoundPage
